import React from 'react'

const WorkItems = ({item}) => {
  return (
    <div className="work__card" key={item.id}>
        <img src={item.image} alt="" className='work__img'/>
        <h3 className="work__title">{item.title}</h3>
        <p className='work__subtitile'>{item.subtitle}</p>
    </div>
  )
}

export default WorkItems