import React from 'react';
import './work.css';
import Works from './Works.jsx';

const Work = () => {
  return (
    <section className="work section" id='portfolio'>
        <h1 className="section__title"> Portfolio</h1>
        <span className="section__subtitle">Most recent works</span>
        
        <Works />
    </section>
  )
}

export default Work